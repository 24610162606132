import React from 'react'

export default function Footer() {
    return (
        <div>
            <div className='footer'>
                <div className='lg-container'>
                    <div className='footer-row'>
                        <div className='content-area'>

                            <img src={require('../images/logo.png')} className='logo' alt="" />
                            <p>
                                Air Travel Claim is a trading style of Legal Ventures Ltd, registered at Swinford House, Albion Street, Brierley Hill, DY5 3EE and is registered in England and Wales with company registration number 13100030. VAT registration number 431840906. ICO Reg No. ZA886718.
                            </p>
                            <div className='social-links'>
                                <a href='https://www.facebook.com/airtravelclaim'>
                                    <i className="fa-brands fa-facebook-f"></i>
                                </a>
                                <a href='https://twitter.com/airclaimuk'>
                                    <i className="fa-brands fa-twitter"></i>
                                </a>
                                <a href='https://www.linkedin.com/company/air-travel-claim/'>
                                    <i className="fa-brands fa-linkedin"></i>
                                </a>
                                <a href='https://www.instagram.com/airtravelclaim/'>
                                    <i className="fa-brands fa-instagram"></i>
                                </a>
                            </div>
                        </div>
                        <div className='content-area'>
                            <h6>Pages</h6>
                            <div className='nav-link'>
                                <a href='https://www.airtravelclaim.com/'>Home</a>
                            </div>
                            <div className='nav-link'>
                                <a href='https://www.airtravelclaim.com/about-us/'>About Air Travel Claim</a>
                            </div>
                            <div className='nav-link'>
                                <a href='https://www.airtravelclaim.com/faqs/'>FAQ’s</a>
                            </div>
                            <div className='nav-link'>
                                <a href='https://www.airtravelclaim.com/partners/'>Become a Partner</a>
                            </div>
                            <div className='nav-link'>
                                <a href='https://www.airtravelclaim.com/category/useful-information/'>Useful Information</a>
                            </div>
                            <div className='nav-link'>
                                <a href='https://www.airtravelclaim.com/airlines/'>Airlines</a>
                            </div>
                        </div>
                        <div className='content-area'>
                            <h6>Contact</h6>
                            <div className='nav-link'>
                                <a href='https://www.airtravelclaim.com/contact-us/'>Contact us</a>
                            </div>
                        </div>
                        <div className='content-area'>
                            <h6>Other</h6>
                            <div className='nav-link'>
                                <a href='https://www.airtravelclaim.com/blog-list/'>Blog</a>
                            </div>
                            <div className='nav-link'>
                                <a href='https://www.airtravelclaim.com/flight-delay-cancellation-news/'>News</a>
                            </div>
                        </div>
                        <div className='content-area'>
                            <h6>Get In Touch</h6>
                            <div className='nav-link contact'>
                                <a href="tel:0330 808 8636" title="0330 808 8636">
                                    <i className="fa-solid fa-phone"></i>
                                    0330 808 8636
                                </a>
                            </div>
                            <div className='nav-link contact'>
                                <a href="mailto:info@airtravelclaim.com" title="info@airtravelclaim.com">
                                    <i className="fa-solid fa-envelope"></i>
                                    info@airtravelclaim.com
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='bottom'>
                        <div className='bottom-nav'>
                            <p>
                                Copyright 2024 Air Travel Claim. All rights reserved.
                            </p>
                        </div>
                        <div className='bottom-nav'>
                            <a href='https://www.airtravelclaim.com/terms-and-conditions/'>Terms and conditions</a>
                            <a href='https://www.airtravelclaim.com/privacy-policy/'>Privacy Policy</a>
                            <a href='https://www.airtravelclaim.com/complaints-procedure/'>Complaints Procedure</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
